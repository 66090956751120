import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import certificat1 from "../../assets/img/telechargement/bas-surface/certificats/1.jpg"
import certificat2 from "../../assets/img/telechargement/bas-surface/certificats/2.jpg"
 
const BasSurfaceCertificatPage = () => (
  <Layout>
    <SEO title="certificats de qualité Bas Surface" />
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3"> 
      <div class="grid grid-cols-1 md:col-span-3 mx-3"> {/** first element */}
        <h1 class="text-2xl font-bold mb-2 md:mt-3">Certificats de Qualité</h1>
        <div class="border-solid border-2 border-red-500 w-1/12 mb-6"></div>
            <img src={certificat1} alt="certificat Bas Surface" />
            <img src={certificat2} alt="certificat Bas Surface" />
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>
) 

export default BasSurfaceCertificatPage